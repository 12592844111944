
export function dfpBannerAdapter(dfpBanner) {
  return {
    ...dfpBanner,
    audianceTarget: dfpBanner.audienceTarget,
    id: dfpBanner.contentName,
  };
}

export function teaseDataAdapter(item) {
  if (!item) return null;

  return {
    ...item,
    __typename: 'TeaserData',
    inputTemplate: 'TeaserData',
    lastUpdate: item.updateDate,
    path: item.url,
    mobileImageTmp: item.mobileImage,
    image: imageAdapter(item.image),
    media: imageAdapter(item.media),
  };
}

export function imageAdapter(image) {
  if (!image) return null;
  return {
    ...image,
    title: image.caption,
    kind: image.type,
    imageUrlLinkGroup: image.link,
    files: image.files?.map(data => ({
      ...data,
      aspects: data.crops,
      imgName: data.path,
    })),
  };
}

export function listAdapter(data) {
  if (!data) return null;

  return {
    ...data,
    url: data.moreArticlesUrl,
    preventRender: data.excludeBrake,
    userTypes: data.includeUserTypes,
    view: data.listStyle,
    items: data.items?.map(teaseDataAdapter),
    banners: {
      ...data.banners,
      dfpBanners: data.banners?.dfpBanners?.map(dfpBannerAdapter),
    },
  };
}
