// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

type TeaserAuthorsPropTypes = {
  authors: ?({
    contentName: string,
    arabicName?: ?string,
    url?: ?string,
  }[]),
  limit: number,
  miscStyles?: ?StyleProps,
  showArabicAuthor?: ?boolean,
};

export default function TeaserAuthors({
  authors,
  limit,
  miscStyles,
  showArabicAuthor,
}: TeaserAuthorsPropTypes): React.Node {
  const { css, theme, } = useFela({ miscStyles, });
  const className = css({
    display: 'inline',
    extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
  });

  if (!authors || !authors.length) return null;
  const commaMaxIndex = Math.min(authors.length, limit) - 1;
  return (
    <span dir={theme.direction}>
      {authors.slice(0, showArabicAuthor ? 4 : limit).map((author, idx) => (
        <React.Fragment key={author}>
          <address className={className}>
            {author}
            {/* {showArabicAuthor && author.arabicName ? ` ${author.arabicName} ` : null} */}
            {idx < commaMaxIndex ? ', ' : ''}
          </address>
        </React.Fragment>
      ))}
    </span>
  );
}

TeaserAuthors.defaultProps = {
  authors: null,
  showArabicAuthor: false,
  limit: 2,
  miscStyles: null,
};
