import * as React from 'react';

import { useFela, FelaComponent, } from 'react-fela';
import IconError from '../Icon/icons/IconError';

type Props = {
  companyName: string,
};

export default function ClickTrackerInfoFooter({ companyName, }: Props) {
  const { css, theme, } = useFela();
  return (
    <div className={css({
      gridArea: 'promoted',
      color: theme.color('commercial', 'base'),
      fontFamily: theme.fontStacks.commercial,
      paddingStart: '1rem',
      paddingBottom: '1rem',
      extend: [
        theme.type(-1, { untilBp: 'xl', }),
        theme.type(-2, { fromBp: 'xl', }),
      ],
    })}
    >
      <FelaComponent
        as="span"
      >
        <IconError size={2} fill={[ 'commercial', 'base', ]} miscStyles={{ marginEnd: '0.5rem', }} />
        {theme.labelsI18n.cooperateWith(companyName || '')}
      </FelaComponent>
    </div>
  );
}
