// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import IconHeadphones from '../Icon/icons/IconHeadphones';

type PropsType = {
  isHighlight?: boolean,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles?: ?StyleProps,
};
function wrapperStyle({ theme, }) {
  return {
    extend: [
      theme.mq({ until: 's', }, { paddingInlineEnd: '0.5rem', }),
      theme.mq({ from: 's', }, { paddingInlineEnd: '1rem', }),
    ],
  };
}

export default function PodcastIcon({ miscStyles, isHighlight, }: PropsType) {
  const { css, theme, } = useFela(miscStyles);

  return (
    <span className={css(wrapperStyle)}>
      <VisuallyHidden>{theme.podcastI18n.includePodcast}</VisuallyHidden>
      <IconHeadphones
        size={2}
        color={theme.teaserStyle.podcastIconColor(isHighlight)}
        attrs={{ 'aria-hidden': true, }}
      />
    </span>
  );
}

PodcastIcon.defaultProps = {
  isHighlight: false,
  miscStyles: null,
};
