/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import ReactGA from 'react-ga';
import EventTracker from '../../utils/EventTracker';
import Section from '../AutoLevels/Section';
import H from '../AutoLevels/H';
import Button from '../Button/Button';
import IconAlefLogoTransparent from '../Icon/icons/IconAlefLogoTransparent';
import type { ListMarketingTeaserType, } from '../../flowTypes/ListMarketingTeaserType';

type MarketingTeaserViewPropTypes = {
  /** A marketing tool, title and subTitle. */
  marketingTeaser: ListMarketingTeaserType,
};

export default function MarketingTeaserView({
  marketingTeaser,
}: MarketingTeaserViewPropTypes): React.Node {
  const marketingTeaserGaHandler = React.useCallback(() => {
    ReactGA.ga('ec:addPromo', {
      name: 'Homepage-Promo-Tools-Section',
      id: 'Homepage-Promo-Tools-Section',
      position: 'HomePage',
      creative: marketingTeaser.subtitle,
    });
  }, [ marketingTeaser.subtitle, ]);
  React.useEffect(() => {
    window.addEventListener('load', marketingTeaserGaHandler);
    return () => {
      window.removeEventListener('load', marketingTeaserGaHandler);
    };
  }, [ marketingTeaserGaHandler, ]);
  const { css, theme, } = useFela({});
  const titleClasses = css({ color: theme.color('secondary'), });
  const subtitleClasses = css({
    color: theme.color('secondary'),
    extend: [ theme.type(-1), ],
  });

  return (
    <Section>
      <IconAlefLogoTransparent color="secondary" size={3} />
      <H className={titleClasses}>{marketingTeaser.title}</H>
      <div className={subtitleClasses}>{marketingTeaser.subtitle}</div>
      <EventTracker>
        {({ biAction, }) => (
          <Button
            variant="salesOpaque"
            boxModel={{ hp: 4, vp: 0.5, }}
            miscStyles={{ marginTop: '2rem', type: -1, }}
            href={marketingTeaser.href}
            onClick={() => {
              ReactGA.ga('ec:addPromo', {
                name: 'Homepage-Promo-Tools-Section',
                id: 'Homepage-Promo-Tools-Section',
                position: 'HomePage',
                creative: marketingTeaser.subtitle,
              });
              ReactGA.ga('ec:setAction', 'promo_click');
              ReactGA.ga(
                'send',
                'event',
                'Internal Promotions',
                'click',
                'Homepage-Promo-Tools-Section'
              );
              biAction({
                actionCode: 3,
                feature: 'Opinions List',
                featureType: 'Marketing',
                campaignName: 'subscription',
                campaignDetails: 'opinions_default',
              });
            }}
          >
            {marketingTeaser.cta}
          </Button>
        )}
      </EventTracker>
    </Section>
  );
}
