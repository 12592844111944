// @flow
import * as React from 'react';
import {
  type StyleProps,
  parseStyleProps,
} from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import HtzLink from '../HtzLink/HtzLink';
import withA11yState from '../A11yState/withA11yState';
import type { ListExtraLinkType, } from '../../flowTypes/ListExtraLinkType';
import type { ListBiActionType, } from '../../flowTypes/ListBiActionType';


type ExtraLinksCommonProps = {
  /** is the list header horizontal on large viewports */
  isHorizontal: boolean,
  /** A list of links to display. */
  extraLinks: ?(ListExtraLinkType[]),
  extraLinksMiscStyles: ?StyleProps,
  biAction: ?ListBiActionType,
  a11yState: boolean,
};

function ExtraLinks({
  extraLinks,
  isHorizontal,
  a11yState,
  biAction,
  extraLinksMiscStyles,
}: ExtraLinksCommonProps) {
  const { theme, css, } = useFela({ isHorizontal, a11yState, });

  const ulClasses = css(extraLinksClasses);
  const liClasses = css(theme.mq({ until: 'l', }, { display: 'inline-block', }));

  return extraLinks ? (
    <ul className={ulClasses}>
      {/* eslint-disable-next-line react/prop-types */}
      {extraLinks.map((item, idx) => (
        <li key={item.contentId || item.url} className={liClasses}>
          <HtzLink
            href={item.url || item.href}
            onClick={biAction}
            className={css({
              ':hover': { color: theme.color('neutral', -1), },
              ':after':
                // eslint-disable-next-line react/prop-types
                idx !== extraLinks.length - 1
                  ? {
                    content: '" "',
                    display: 'inline-block',
                    marginInlineStart: '1rem',
                    ...theme.mq({ from: 'l', }, { display: 'none', }), // Trump all other styles with those defined in `miscStyles`
                  }
                  : undefined,
              extend: [
                ...(extraLinksMiscStyles
                  ? parseStyleProps(extraLinksMiscStyles, theme.mq, theme.type)
                  : []),
              ],
            })}
            {...(item.openNewTab
              ? {
                target: '_blank',
                rel: 'noopener norferrer',
              }
              : {})}
          >
            {item.linkText || item.title}
          </HtzLink>
        </li>
      ))}
    </ul>
  ) : null;
}

function extraLinksClasses({ isHorizontal, a11yState, theme, }) {
  return {
    fontFamily: theme.fontStacks[theme.framedFont],
    color: theme.color('neutral', a11yState ? '-2' : '-3'),
    ...(isHorizontal ? { marginInlineStart: 'auto', } : {}),
    extend: [
      theme.mq({ until: 's', }, { display: 'none', }),
      theme.mq({ until: 'l', }, { fontWeight: '700', }),
      theme.type(-1, { fromBp: 's', untilBp: 'l', }),
      theme.type(-1, { fromBp: 'l', untilBp: 'xl', lines: 4, }),
      theme.type(-2, { fromBp: 'xl', lines: 4, }),
      ...(isHorizontal
        ? []
        : [
          theme.mq({ until: 'l', }, { marginInlineStart: 'auto', }),
          theme.mq({ from: 'l', }, { marginTop: '1rem', }),
        ]),
    ],
  };
}

export default withA11yState<ExtraLinksCommonProps>(ExtraLinks);
