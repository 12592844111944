import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, parseTypographyProp, } from '@haaretz/htz-css-tools';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';
import { stylesPropType, } from '../../propTypes/stylesPropType';

Kicker.propTypes = {
  /**
   * Sets heading element to be _block_ or _inline_.(default is inline)
   */
  isBlock: PropTypes.bool,
  /**
   * Font-size configuration for the heading.<br/>
   * Can be an int, defined at: [theme typography](https://haaretz.github.io/htz-frontend/docs/htz-theme/#typographic-scale)
   * Or an array of responsive-prop-object.
   * ```js
   *  { from, until, value, }
   * ```
   */
  fontSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: PropTypes.number,
      })
    ),
  ]),
  /**
   * string to use as divider
   */
  divider: PropTypes.string,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
  /**
   * Text of kicker
   */
  text: PropTypes.string,
  children: PropTypes.node,
  /**
   * A special property holding miscellaneous CSS values that
   * trumps all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  innerMiscStyles: stylesPropType,
  tagName: PropTypes.string,
};
Kicker.defaultProps = {
  text: null,
  fontSize: null,
  isBlock: false,
  miscStyles: null,
  innerMiscStyles: null,
  tagName: 'span',
  // Non breaking space at the beginning and a regular space at the end
  divider: ' | ',
  displayOnMobile: true,
  displayOnDesktop: true,
  children: null,
};

const style = ({
  isBlock,
  fontSize,
  divider,
  miscStyles,
  theme,
  displayOnMobile,
  displayOnDesktop,
}) => ({
  display: isBlock ? 'block' : 'inline',

  color: theme.color('articleHeader', isBlock ? 'kickerBlockText' : 'kickerInlineText'),
  ...(isBlock
    ? {
      fontWeight: '700',
    }
    : {
      wordBreak: 'break-word',
      backgroundColor: theme.color('articleHeader', 'kickerInlineBg'),
    }),
  extend: [
    theme.mq({ until: 's', }, displayOnMobile ? {} : { display: 'none', }),
    theme.mq({ from: 's', }, displayOnDesktop ? {} : { display: 'none', }),
    ...[ fontSize || typeof fontSize === 'number' ? parseTypographyProp(fontSize, theme.type) : {}, ],
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const innerStyle = ({ isBlock, theme, innerMiscStyles, }) => ({
  ...(isBlock
    ? {
      paddingInlineStart: '1rem',
      paddingInlineEnd: '1rem',
      display: 'inline-block',
      backgroundColor: theme.color('articleHeader', 'kickerBlockBg'),
    }
    : {}),
  extend: [ ...(innerMiscStyles ? parseStyleProps(innerMiscStyles, theme.mq, theme.type) : []), ],
});

export default function Kicker({
  isBlock,
  fontSize,
  text,
  divider,
  displayOnMobile,
  displayOnDesktop,
  miscStyles,
  innerMiscStyles,
  tagName,
  children,
}) {
  const { css, } = useFela({
    isBlock,
    fontSize,
    divider,
    displayOnMobile,
    displayOnDesktop,
    miscStyles,
    innerMiscStyles,
  });
  const outerClasses = css(style);
  const innerClasses = css(innerStyle);

  const Tag = tagName;
  return (
    <Tag className={outerClasses}>
      <span className={innerClasses} data-test="kicker">
        {text || children}
        {isBlock ? null : divider}
      </span>
    </Tag>
  );
}
