/* global fetch */
// @flow
import React from 'react';
import dynamic from 'next/dynamic';
import type { Node, } from 'react';
import { useRouter, } from 'next/router';
import config from 'config';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import { useIsBot, } from '../../hooks/useIsBot';
import type { ListDataType, } from '../../flowTypes/ListDataType';

import { getTeasersInPage, updateTeasersInPage, } from './ListDuplication';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import { usePlatformRender, } from '../../utils/getElementsFactory';
import useArticleId from '../../hooks/Page/useArticleId';
import { useUser, } from '../User/UserDispenser';
import useOneTime from '../../hooks/useOneTime';
import useTagsData from '../../hooks/Page/useTagsData';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import { listAdapter, } from './ListAdapters';

const ClientSideListWrapper = dynamic(import('./ClientSideListWrapper'), {
  loading: () => null,
  ssr: false,
});

const PersonalListWrapper = dynamic(import('./PersonalListWrapper'), {
  loading: () => null,
  ssr: false,
});

type ListProps = ListDataType & {
  viewProps?: ?Object,
};

const AbTestListSrvUrl = `${config.get('service.brightspot')}/srv/personalization/get-ab-test-list`;

const getLocalStorageItem = key => {
  if (typeof window !== 'undefined' && window.localStorage) {
    try {
      return window.localStorage.getItem(key);
    }
    catch (err) {
      console.error(err);
      return null;
    }
  }
  return null;
};

// eslint-disable-next-line react/default-props-match-prop-types
List.defaultProps = { viewProps: {}, };

function List({ viewProps, ...listData }: ListProps): Node {
  const getListView = useGetComponent();
  const isWebView = useWebViewChecker();
  const { isBot, } = useIsBot();
  const {
    contentId,
    loadPriority,
    view,
    dedupeReadingHistory,
    isAddContentIdsToGlobalSet = true,
    displayOnlyInApp,
    excludePlatform,
    listKind,
    abTestContentId,
  } = listData;

  const appearByPlatform = usePlatformRender(excludePlatform);

  if (!appearByPlatform) {
    return null;
  }

  if (displayOnlyInApp === true && !isWebView) return null;

  const isSsr = listData && ((listData?.items?.length > 0 && listData.loadPriority === 'ssr') || isBot);
  const ListView = getListView(view);
  const isPersonal = listData?.isPersonal && !isBot;
  const isAbTestList = listKind === 'abTest' && !!abTestContentId;

  const listType = isAbTestList || (isPersonal && loadPriority !== 'ssr')
    ? 'personal'
    : isSsr
      ? 'ssr'
      : 'client';

  const listDefaultProps = {
    listData,
    viewProps,
    variables: {
      listId: contentId,
      history: getTeasersInPage(),
      getHistory: getTeasersInPage,
    },
  };

  const DefaultListWrapper = ({ children, }) => children({ updateListDuplication: updateTeasersInPage, });

  const listWrappers = new Map([
    [
      'personal',
      {
        WrapperComponent: PersonalListWrapper,
        wrapperProps: {
          getListDuplication: getTeasersInPage,
          dedupeReadingHistory,
          isAddContentIdsToGlobalSet,
          updateListDuplication: updateTeasersInPage,
          view,
          contentId,
        },
      },
    ],
    [
      'client',
      {
        WrapperComponent: ClientSideListWrapper,
        wrapperProps: {
          getListDuplication: getTeasersInPage,
          dedupeReadingHistory,
          isAddContentIdsToGlobalSet,
          contentId,
          view,
          updateListDuplication: updateTeasersInPage,
        },
      },
    ],
    [
      'ssr',
      {
        WrapperComponent: DefaultListWrapper,
        wrapperProps: {},
      },
    ],
  ]);

  const { WrapperComponent, wrapperProps, } = listWrappers.get(listType) || {
    WrapperComponent: DefaultListWrapper,
    wrapperProps: {},
  };

  return (
    <WrapperComponent {...wrapperProps}>
      {listProps => <ListView {...listDefaultProps} {...listProps} />}
    </WrapperComponent>
  );
}

type ListKindResolverProps = ListProps & {
  abTestContentId?: String,
  listKind?: String,
};

ListKindResolver.defaultProps = { contentId: '', list: null, };

function ListKindResolver(props: ListKindResolverProps): Node {
  const { abTestContentId, listKind, } = props;
  const articleId = useArticleId();
  const { isBot, } = useIsBot();
  const { user, } = useUser();
  const router = useRouter();
  const breadCrumbs = useBreadcrumbsData();
  const tags = useTagsData();

  const isAbTestList = listKind === 'abTest' && !!abTestContentId;
  const shouldRenderAbList = isAbTestList && !isBot && !!user?.email;

  const [ abListLoading, setAbListLoading, ] = React.useState(shouldRenderAbList);
  const [ abListData, seAbListData, ] = React.useState(null);

  // This code retrieves values from local storage to exclude specific IDs
  // from homepage lists for personalization data.
  const excludedIds = [];
  const krokerExclude = getLocalStorageItem('KrokerExclude');
  const boxyExclude = getLocalStorageItem('boxyExclude');
  const spotExclude = getLocalStorageItem('spotExclude');
  // homepage list exclude
  if (krokerExclude && !articleId) {
    excludedIds.push(krokerExclude);
  }
  // homepage list exclude
  if (boxyExclude && !articleId) {
    excludedIds.push(boxyExclude);
  }
  // article page exclude
  if (spotExclude && !!articleId) {
    excludedIds.push(spotExclude);
  }

  // This code creates a string that contains the excluded IDs retrieved from local storage
  // separated by a comma and a space.
  const ListItemsIdsExcluded = excludedIds.length > 0 ? excludedIds.join(', ') : undefined;

  // map tags content ids
  const tagsContentIds = Array.isArray(tags) && tags.map(item => item.contentId).join(', ');
  // get section id from breadcrumbs
  const sectionId = Array.isArray(breadCrumbs) && breadCrumbs.slice(-2, -1)[0]?.contentId;

  let pageType = '';

  if (isAbTestList) {
    const path = router.query.path || '';

    pageType = path === '/'
      ? 'homepage' : path.includes('ty-article')
        ? 'article' : 'section';
  }


  useOneTime(shouldRenderAbList, async () => {
    setAbListLoading(true);

    const args = {
      testId: abTestContentId,
      pageType,
      // we add the last article id to the query
      // so homepage will be updated when user read an article
      articleId: articleId || getLocalStorageItem('lastArticleId'),
      ...(ListItemsIdsExcluded
        ? { exclude: typeof ListItemsIdsExcluded === 'string' ? ListItemsIdsExcluded : '', }
        : {}),
      ...articleId ? { sectionId, tagIds: tagsContentIds, } : {},
    };
    const res = await fetch(AbTestListSrvUrl, { method: 'POST', credentials: 'include', body: JSON.stringify(args), });

    try {
      const resData = await res.json();

      const data = listAdapter(resData);

      seAbListData(data);
    }
    catch (error) {
      console.log('Ab test list data getter', error);
    }
    finally {
      setAbListLoading(false);
    }
  });


  if (!shouldRenderAbList) {
    return <List {...props} />;
  }

  if (abListLoading) return null;

  return <List {...(isBot || !abListData?.items?.length ? props : abListData)} />;
}

export default React.memo<ListProps>(ListKindResolver);
