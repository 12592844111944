// @flow

import * as React from 'react';
import type { Node, } from 'react';
import { useFela, } from 'react-fela';
import dynamic from 'next/dynamic';

import {
  type ComponentPropResponsiveObject,
  type StyleProps,
  type TypographyPropType,
  parseComponentProp,
  parseStyleProps,
  parseTypographyProp,
} from '@haaretz/htz-css-tools';
import type { attrFlowType, } from '../../flowTypes/attrTypes';
import type { CountdownType, } from '../../flowTypes/CountdownType';

import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import Kicker from '../ArticleHeader/Kicker';
import TeaserResponsiveText from '../TeaserResponsiveText/TeaserResponsiveText';
import style from './teaserHeaderStyle';
import getTeaserHref from '../../utils/getTeaserHref';

const Countdown = dynamic(() => import('../Countdown/Countdown'), {
  ssr: true,
  loading: () => null,
});

type IsCenteredType = boolean | Array<ComponentPropResponsiveObject<boolean>>;

type TeaserHeaderProps = {
  /** attributes to be passed to the DOM element */
  attrs: ?attrFlowType,
  /**
   * The offset of the `h` element from the calculated heading level.
   * e.g: the calculated level is 3 and the offset is 1, the heading level will be 4.
   * The offset can be negative.
   * The final Heading level can be between 2 and 6,
   * e.g the calculated level is 10, the heading level will be 6.
   */
  offset: number,
  /** Forces the headline element to be an H1 */
  isH1: boolean,
  title: string,
  titleMobile: ?string,
  exclusive: ?string,
  exclusiveMobile: ?(string | Node),
  hash: ?string,
  path: string,
  openInNewWindow: ?boolean,

  /**
   * The font-size and line height of the headline
   * Can be:
   *   - A `number` representing a step in the typographic scale.
   *   - an object of the following structure:
   *     ```ts
   *     {
   *       step: number, // A step in the typographic scale
   *       lines?: number, // overrides the default number of vertical rhythm
   *                       // lines each line of text occupies.
   *     }
   *     ```
   *   - An array of objects representing media queries, in
   *     the following structure:
   *     ```
   *     {
   *       from?: string,
   *       until?: string,
   *       misc?: string,
   *       value: number | the above object,
   *     }
   *     ```
   */
  typeScale: ?TypographyPropType,
  /**
   * The color of the headline
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   *   - An array of objects representing media queries, in
   *     the following structure:
   *     ```
   *     {
   *       from?: string,
   *       until?: string,
   *       misc?: string,
   *       value: string or tuple, as mentioned above,
   *     }
   *     ```
   */
  color:
    | ?string
    | [string, ]
    | [string, string, ]
    | ComponentPropResponsiveObject<string | [string, ] | [string, string, ]>[],
  /** make the entire teaser area linkable */
  hasBlockLink: boolean,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  /**
   * A special property holding miscellaneous CSS values for the kicker that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  kickerMiscStyles: ?StyleProps,
  kickerInnerMiscStyles: ?StyleProps,

  /** Determines if the kicker element is set as a block element */
  kickerIsBlock: boolean,
  /**
   * The font-size and line height of the headline
   * Can be:
   *   - A `number` representing a step in the typographic scale.
   *   - an object of the following structure:
   *     ```ts
   *     {
   *       step: number, // A step in the typographic scale
   *       lines?: number, // overrides the default number of vertical rhythm
   *                       // lines each line of text occupies.
   *     }
   *     ```
   *   - An array of objects representing media queries, in
   *     the following structure:
   *     ```
   *     {
   *       from?: string,
   *       until?: string,
   *       misc?: string,
   *       value: number | the above object,
   *     }
   *     ```
   */
  kickerTypeScale: ?TypographyPropType,
  isCentered: IsCenteredType,
  /** Is this a direct descendent of a css-grid container */
  isGridItem: boolean,
  /**
   * pass an `onClick` event to the blockLink.
   * Useful for bi actions and events
   *
   * Should also be passed to underlying links, e.g.,
   * around the title and image
   */
  onClick: ?(evt: SyntheticMouseEvent<HTMLElement>) => void,
  showKicker: boolean,
  countdownMiscStyles: ?StyleProps,
  countdownObj: ?CountdownType,
  /**
   * A special property holding miscellaneous CSS values on the wrapper that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  wrapperMiscStyles: ?StyleProps,
  excludeLink?: boolean;
};

TeaserHeader.defaultProps = {
  attrs: null,
  countdownMiscStyles: null,
  countdownObj: null,
  isH1: false,
  offset: 0,
  onClick: null,
  // data props
  exclusive: null,
  exclusiveMobile: null,
  hash: null,
  openInNewWindow: false,
  titleMobile: null,
  // style props
  color: null,
  hasBlockLink: true,
  isCentered: false,
  isGridItem: false,
  kickerInnerMiscStyles: null,
  kickerIsBlock: false,
  kickerMiscStyles: null,
  kickerTypeScale: null,
  miscStyles: null,
  showKicker: true,
  typeScale: null,
  wrapperMiscStyles: null,
  excludeLink: false,
};

export default function TeaserHeader({
  attrs,
  countdownObj,
  isH1,
  offset,
  onClick,
  // data props
  exclusive,
  exclusiveMobile,
  hash,
  openInNewWindow,
  path,
  title,
  titleMobile,
  // style props
  color,
  countdownMiscStyles,
  hasBlockLink,
  isCentered,
  isGridItem,
  kickerInnerMiscStyles,
  kickerIsBlock,
  kickerMiscStyles,
  kickerTypeScale,
  miscStyles,
  showKicker,
  typeScale,
  wrapperMiscStyles,
  excludeLink,
}: TeaserHeaderProps): React.Node {
  const { css, } = useFela({
    color,
    hasBlockLink,
    isCentered,
    isGridItem,
    kickerInnerMiscStyles,
    kickerIsBlock,
    kickerMiscStyles,
    kickerTypeScale,
    miscStyles,
    showKicker,
    typeScale,
    wrapperMiscStyles,
  });

  const wrapperClasses = css(wrapperStyle);
  const headlineClasses = css(style);
  const href = getTeaserHref({ path, hash, });

  const Link = excludeLink
    ? ({ children, }) => <React.Fragment>{children}</React.Fragment>
    : HtzLink;

  return (
    <div className={wrapperClasses}>
      <Link href={href} onClick={onClick} target={openInNewWindow ? '_blank' : undefined}>
        {showKicker && (exclusive || exclusiveMobile) && (
          <Kicker
            {...(kickerIsBlock ? { isBlock: kickerIsBlock, } : {})}
            {...(kickerTypeScale || typeof kickerTypeScale === 'number' ? { fontSize: kickerTypeScale, } : {})}
            {...(kickerMiscStyles ? { miscStyles: kickerMiscStyles, } : {})}
            {...(kickerInnerMiscStyles ? { innerMiscStyles: kickerInnerMiscStyles, } : {})}
            displayOnMobile={!!exclusiveMobile}
            displayOnDesktop={!!exclusive}
          >
            <TeaserResponsiveText text={exclusive} mobileText={exclusiveMobile} displayStrict />
          </Kicker>
        )}
        {countdownObj ? (
          <Countdown {...countdownObj} miscStyles={countdownMiscStyles} />
        ) : null}
        <H className={headlineClasses} isH1={isH1} offset={offset} {...attrs || {}}>
          <TeaserResponsiveText text={title} mobileText={titleMobile} />
        </H>
      </Link>
    </div>
  );
}

function wrapperStyle({ isCentered, isGridItem, typeScale, wrapperMiscStyles, theme, }) {
  return {
    gridArea: isGridItem ? 'content' : undefined,
    extend: [
      ...[
        isCentered
          ? parseComponentProp<IsCenteredType>('textAlign', isCentered, theme.mq, centerText) // eslint-disable-line space-infix-ops, no-mixed-operators
          : {},
      ],
      // Set font-size and line-height
      ...[ typeScale ? parseTypographyProp(typeScale, theme.type) : {}, ],
      ...(wrapperMiscStyles ? parseStyleProps(wrapperMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function centerText(prop: string, isCentered: boolean) {
  return isCentered ? { textAlign: 'center', } : {};
}
