// @flow

import * as React from 'react';
import type { Node, } from 'react';
import { useFela, } from 'react-fela';

type TextPropTypes = {
  text: ?(string | [string, ]),
  mobileText: ?(string | Node | [string, ]),
  // displayStrict will cause mobileText to only display
  // on small breakpoint and text to only display on larger than small breakpoint
  // the default behaviour is to fall back to one of the texts if the other is missing
  displayStrict?: boolean,
  highlight: ?([[string, ], ] | string),
};

TeaserResponsiveText.defaultProps = {
  text: null,
  mobileText: null,
  displayStrict: false,
  highlight: null,
};

export default function TeaserResponsiveText({
  text,
  mobileText,
  displayStrict,
  highlight,
}: TextPropTypes): React.Node {
  const { css, theme, } = useFela();

  if (displayStrict || (mobileText && text && mobileText !== text)) {
    return (
      <>
        {mobileText ? (
          <span
            className={css({
              ...theme.mq({ from: 's', }, { display: 'none', }),
            })}
          >
            {highlightArray(mobileText, highlight)}
          </span>
        ) : null}
        {text ? (
          <span className={css({ ...theme.mq({ until: 's', }, { display: 'none', }), })}>{highlightArray(text, highlight)}</span>
        ) : null}
      </>
    );
  }

  return highlightArray(mobileText, highlight) || highlightArray(text, highlight) || null;
}

type PartItemPropTypes = {
  part?: string,
  isStrong?: boolean,
};


PartItem.defaultProps = {
  part: null,
  isStrong: false,
};

function PartItem({ part, isStrong, }: PartItemPropTypes) {
  if (isStrong) {
    return <strong>{part}</strong>;
  }
  return part;
}

// 'stringArr' is an array of string, 'substringArrArr' is an array of array of strings
function highlightArray(stringArr, substringArrArr) {
  if (!Array.isArray(stringArr) || !Array.isArray(substringArrArr)) {
    // if input is not an array (could be a string or null), do not change anything
    return stringArr;
  }
  return stringArr.filter(item => item).map((item, index) => highlightString(item, substringArrArr[index], `sec-${index}`));
}

// 'string' is a string, 'substringArr' is an array of strings
function highlightString(string, substringArr, key) {
  if (!string) {
    return null;
  }
  if (!substringArr || substringArr.length === 0) {
    return <PartItem key={`${key}`} part={string} />;
  }
  const parts = string.split(new RegExp(`(${substringArr.join('|')})`, 'gi'));
  return parts.map((part, index) => (<PartItem key={`${key}-part-${index}`} part={part} isStrong={substringArr.map(item => item.toLowerCase()).includes(part.toLowerCase())} />));
}
