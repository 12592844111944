// @flow

import { useQuery, } from 'react-apollo';
import { TAGS_QUERY, } from '@haaretz/graphql';
import useArticleId from './useArticleId';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

export default function useTagsData() {
  const articleId = useArticleId();
  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(TAGS_QUERY, {
    fetchPolicy: 'cache-first',
    skip: !articleId,
    variables: queryVariables,
  });

  if (loading) return null;
  if (error) {
    console.error('Tags error', error);
    return null;
  }
  if (!data) return null;

  return data.Page?.slots?.article?.tags;
}
