// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import { STK_QUERY, } from '@haaretz/graphql';
import {
  // parseComponentProp,
  parseStyleProps,
  parseTypographyProp,
  type StyleProps,
  type TypographyPropType,
} from '@haaretz/htz-css-tools';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import type { TeaserDataType, } from '../../flowTypes/TeaserDataType';

import withA11yState from '../A11yState/withA11yState';
import Query from '../ApolloBoundary/Query';
import AboveBlockLink from '../BlockLink/AboveBlockLink';
import CommentsCount from '../CommentsCount/CommentsCount';
import HtzLink from '../HtzLink/HtzLink';
import LiveUpdateView from '../LiveUpdateView/LiveUpdateView';
import TeaserAuthors from '../TeaserAuthors/TeaserAuthors';
import TeaserRank from '../TeaserRank/TeaserRank';
import TeaserTime from '../TeaserTime/TeaserTime';
import PodcastIcon from './PodcastIcon';

type TeaserFooterProps = {
  showArabicAuthor?: ?boolean,
  commentsColor: ?ColorPropType,
  data: TeaserDataType,
  isDark: boolean,
  miscStyles: ?StyleProps,
  tickerMiscStyles: ?(boolean) => StyleProps,
  noCommentsOnMobile: boolean,
  noRankOnMobile: boolean,
  reportingFrom: string,
  showAuthor: boolean,
  showLive: boolean,
  showRank: boolean,
  showTime: boolean,
  showMediaFlags: boolean,
  a11yState: boolean,
  showCommentsCount?: boolean,
  isHighlight?: boolean,
  typeScale: TypographyPropType,
  swapReportingFromAndTime?: boolean,
};

TeaserFooter.defaultProps = {
  showArabicAuthor: false,
  commentsColor: undefined,
  isDark: false,
  miscStyles: null,
  tickerMiscStyles: null,
  noCommentsOnMobile: false,
  noRankOnMobile: false,
  reportingFrom: null,
  showAuthor: false,
  showLive: false,
  showRank: false,
  showTime: true,
  showMediaFlags: false,
  a11yState: false,
  isHighlight: false,
  showCommentsCount: true,
  typeScale: [ { until: 'xl', value: -2, }, { from: 'xl', value: -3, }, ],
  swapReportingFromAndTime: false,
};

function getLiveUpdateColor(isHighlight, isDark, data) {
  const isClimate = false; // data?.path?.indexOf('/nature/climate') > -1;

  return isHighlight
    ? [ (isClimate ? 'positive' : 'negative'), '-2', ]
    : isDark ? [ (isClimate ? 'positive' : 'tertiary'), '-2', ]
      : (isClimate ? 'positive' : 'tertiary');
}

function TeaserFooter({
  commentsColor,
  data,
  miscStyles,
  isDark,
  tickerMiscStyles,
  noCommentsOnMobile,
  noRankOnMobile,
  showAuthor,
  showArabicAuthor,
  showLive,
  showRank,
  showTime,
  showMediaFlags,
  typeScale,
  a11yState,
  showCommentsCount,
  isHighlight,
  swapReportingFromAndTime,
}: TeaserFooterProps): React.Node {
  const { css, theme, } = useFela({ miscStyles, typeScale, noRankOnMobile, a11yState, });
  const wrapperClasses = css(footerStyle);
  const rankWrapperClasses = css(rankWrapperStyle);
  const displayTime = showTime && !!(data.publishDate || data.lastUpdate);
  const hasAuthor = showAuthor && !!data.credit?.[0] || !!data.authors?.[0]?.contentName;
  const hasMinComments = typeof data.commentsCount === 'number' && data.commentsCount > 4;
  const hasAsset = !!data.assetId;
  const showPodcastIcon = showMediaFlags && !!data.mediaFlags && !!data.mediaFlags.podcast;
  const shouldRenderFooter = hasAuthor
    || hasMinComments
    || hasRank(showRank, data.rank)
    || hasAsset
    || showLive
    || showPodcastIcon;

  return shouldRenderFooter ? (
    <footer className={wrapperClasses}>
      {showPodcastIcon && <PodcastIcon isHighlight={isHighlight} />}
      {showLive && (
        <LiveUpdateView color={getLiveUpdateColor(isHighlight, isDark, data)} />
      )}
      {hasAuthor ? (
        <React.Fragment>
          <span>
            <TeaserAuthors showArabicAuthor={showArabicAuthor} authors={data.credit || [ data.authors?.[0]?.contentName, ]} miscStyles={{ fontWeight: 'bold', }} />
          </span>
          {data.reportingFrom && swapReportingFromAndTime ? (
            <span className={css({
              '&:before': {
                content: '"\\00A0|\\00A0"',
              },
            })}
            >
              {data.reportingFrom}
            </span>
          ) : null}
          {displayTime ? (
            <TeaserTime
              className={css({
                '&:before': {
                  content: '"\\00A0|\\00A0"',
                },
              })}
              {...data}
            />
          ) : null}
          {data.reportingFrom && !swapReportingFromAndTime ? (
            <span className={css({
              '&:before': {
                content: '"\\00A0|\\00A0"',
              },
            })}
            >
              {data.reportingFrom}
            </span>
          ) : null}
          <span>&#32;</span>
        </React.Fragment>
      ) : null}
      {showCommentsCount ? (
        <CommentsCount
          color={commentsColor}
          commentsCount={data.commentsCount}
          minCount={5}
          miscStyles={{
            ...(swapReportingFromAndTime ? { marginStart: '1rem', } : null),
            ...(noCommentsOnMobile ? { display: [ { until: 's', value: 'none', }, ], } : null),
          }}
        />
      ) : null}
      <span>&#32;</span>
      {hasRank(showRank, data.rank) ? (
        <span className={rankWrapperClasses}>
          <TeaserRank rank={data.rank} direction={theme.direction} />
          <span>&#32;</span>
        </span>
      ) : null}
      {hasAsset ? (
        <Query query={STK_QUERY} ssr={false} variables={{ ids: [ data.assetId, ], }}>
          {({ data: assetData, error, loading, }) => {
            if (error) return null;
            if (loading) return null;
            if (!assetData.assets?.length) return null;

            const asset = assetData.assets[0];
            const isPositive = asset.changePercentage >= 0;
            return (
              <AboveBlockLink>
                {({ className, }) => {
                  const color = theme.color(
                    ...(isPositive
                      ? isDark ? [ 'primary', '-2', ] : [ 'positive', 'base', ]
                      : [ 'negative', isDark ? '-2' : 'base', ])
                  );
                  return (
                    <HtzLink
                      href={`https://finance.themarker.com/${asset.type}/${asset.id}`}
                      target="_blank"
                      className={`${className} ${css({
                        color,
                        fontWeight: 700,
                        whiteSpace: 'nowrap',
                        ':visited': { color, },
                        ':hover': { color, },
                        extend: [
                          ...(tickerMiscStyles
                            ? parseStyleProps(tickerMiscStyles(isPositive), theme.mq, theme.type)
                            : []),
                        ],
                      })}`}
                    >
                      <span
                        className={css({
                          position: 'relative',
                          top: '2rem',
                          width: 0,
                          height: 0,
                          borderLeft: '0.4em solid transparent',
                          borderRight: '0.4em solid transparent',
                          ...(isPositive
                            ? {
                              borderBottom: '0.7em solid',
                              top: '-1.05em',
                            }
                            : {
                              top: '0.95em',
                              borderTop: '0.7em solid',
                            }),
                        })}
                      >
                        &zwj;
                      </span>
                      <span>
                        &nbsp;
                        {asset.name}
                        &nbsp;
                      </span>
                      <span>&#32;</span>
                      <span dir="ltr">
                        {`${isPositive ? '+' : ''}${asset.changePercentage}${'%'}`}
                      </span>
                    </HtzLink>
                  );
                }}
              </AboveBlockLink>
            );
          }}
        </Query>
      ) : null}
    </footer>
  ) : null;
}

function hasRank(showRank: boolean, rank: ?number): boolean %checks {
  return showRank && typeof rank === 'number' && rank > 0;
}

function rankWrapperStyle({ noRankOnMobile, theme, }) {
  return noRankOnMobile ? theme.mq({ until: 's', }, { display: 'none', }) : null;
}

function footerStyle({ theme, miscStyles, typeScale, a11yState, }) {
  return {
    fontFamily: theme.fontStacks[theme.framedFont],
    color: theme.color('neutral', a11yState ? '-2' : '-3'),
    gridArea: 'footer',
    /* TODO: Removed overflow property due to Chrome issue - `fr` is not calculated as expected
    when a grid item without an explicit height has overflow set to anything but `visible`.
    Restore `overflow: hidden` if and when issue resolves. */
    // overflow: 'hidden',
    extend: [
      parseTypographyProp(typeScale, theme.type),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

export default withA11yState(TeaserFooter);
