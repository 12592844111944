// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import differenceInHours from 'date-fns/differenceInHours';
import Time from '../Time/Time';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import { useIsBot, } from '../../hooks/useIsBot';

const { isHDC, } = checkSiteFromConfig();

type TeaserTimePropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** wrapper className override, passed on to the Time component two dates wrapper */
  twoDatesWrapperClassName: ?string,
  /** The article publish time in unixtime (ms) */
  publishDate: ?number,
  isNewFullDateFormat?: boolean,
  /** The article last update time in unixtime (ms) */
  lastUpdate: ?number,
  labels: ?{
    pubDateAtText: string,
    pubDateOnText: string,
    modDateAtText: string,
    modDateOnText: string,
  },
  format?: ?(string | {
    from: string,
    until: string,
    format: string, }),
  /** Should there be a separating element between publish and update dates (default seperator is "|") */
  withSeparator: boolean,
  /**
   * A custom separator to be used between the publish and update dates,
   * in case the two are rendered and `withSeparator` is true.
  */
  separator: React.Node,
};

TeaserTime.defaultProps = {
  className: null,
  twoDatesWrapperClassName: null,
  publishDate: null,
  lastUpdate: null,
  labels: null,
  isNewFullDateFormat: false,
  format: null,
  withSeparator: false,
  separator: null,
};

const hdcFullDateRender = (isBot: boolean, split = false) => (time: string) => {
  if (isBot) {
    return time.replace('GMT', 'UTC');
  }

  const formattedTime = time.includes('GMT+')
    ? time.replace('GMT+2', 'IST').replace('GMT+3', 'IDT')
    : time;

  return split
    ? formattedTime.split('##').map((item, i) => (i === 0 ? <VisuallyHidden>{item}</VisuallyHidden>
      : <>{item}</>)) : formattedTime.replace('##', '');
};


export default function TeaserTime({
  className,
  twoDatesWrapperClassName,
  publishDate,
  lastUpdate,
  labels,
  isNewFullDateFormat,
  format,
  separator,
}: TeaserTimePropTypes): React.Node {
  const { css, theme, } = useFela({ isNewFullDateFormat, });
  const isWebView = useWebViewChecker();
  const { isBot, } = useIsBot();
  const pertinentDate = lastUpdate || publishDate;
  if (!pertinentDate) return null;
  const isDateToday = isToday(pertinentDate);
  const isNewHdcDisplay = isNewFullDateFormat && isHDC;
  const sameDayFormat = isNewFullDateFormat
    ? theme.timeLabels[`fullDate${isBot ? 'Bot' : ''}`]
    : theme.timeLabels.sameDayTimeFormat;

  const timeFormat = format || (isDateToday
    ? sameDayFormat
    : theme.timeLabels.timeFormat);

  // If labels are not defined return only one time component with timeFormat.
  // (Labels are not passed in TeaserFooter but are passed in ArticleHeaderMeta)
  if (!labels) {
    if (!publishDate) return null;

    return (
      <Time className={className} format={timeFormat} time={publishDate} />
    );
  }

  const showBothDates = lastUpdate !== publishDate
    && (isDifferentDay(lastUpdate, publishDate) || isDateToday)
    && !isWebView;

  if (showBothDates) {
    return (
      <div className={twoDatesWrapperClassName}>
        <Time
          className={className}
          format={[
            { from: '-1d', until: '+0d', format: sameDayFormat, },
            { format: theme.timeLabels.timeFormat, },
          ]}
          time={publishDate}
          // TODO: check if this logic is good for google data
          // withSeparator={!isNewHdcDisplay && !seperator}
          {...(labels && !isNewHdcDisplay ? { label: isDateToday ? labels.pubDateAtText : labels.pubDateOnText, } : {})}
          {...(isNewHdcDisplay ? { timeZone: 'Asia/Jerusalem', render: hdcFullDateRender(isBot), } : {})}
        />
        {separator}
        <Time
          className={isNewHdcDisplay ? `${className || ''} ${css({
            extend: [
              theme.mq({ from: 'l', }, { marginTop: '0rem', }),
            ], })}` : className}
          format={[
            { from: '-1d', until: '+0d', format: sameDayFormat, },
            { format: theme.timeLabels.timeFormat, },
          ]}
          time={lastUpdate}
          {...(labels ? { label: isDateToday ? labels.modDateAtText : labels.modDateOnText, } : {})}
          {...(isNewHdcDisplay ? {
            timeZone: 'Asia/Jerusalem',
            render: hdcFullDateRender(isBot, isDateToday),
          } : {})}
        />
      </div>
    );
  }
  // Show publish date with full time format.
  return (
    <Time
      className={className}
      format={timeFormat}
      time={isWebView ? pertinentDate : publishDate}
      {...(isNewHdcDisplay ? {
        timeZone: 'Asia/Jerusalem',
        render: hdcFullDateRender(isBot),
      } : {})}
      {...(labels && !isNewHdcDisplay ? {
        label: isDateToday ? labels.pubDateAtText : labels.pubDateOnText,
      } : {

      })}
    />
  );
}

function isToday(time) {
  return differenceInHours(Date.now(), new Date(time)) < 24;
}
function isDifferentDay(lastUpdate, publishDate) {
  if (!(lastUpdate && publishDate)) return null;
  return differenceInHours(new Date(lastUpdate), new Date(publishDate)) > 24;
}
