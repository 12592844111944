import React from 'react';
import { FelaComponent, useFela, } from 'react-fela';
import PropTypes from 'prop-types';
import { border, } from '@haaretz/htz-css-tools';
import Image from '../../Image/Image';
import HtzLink from '../../HtzLink/HtzLink';
import EventTracker from '../../../utils/EventTracker';
import ClickTrackerInfoFooter from '../ClickTrackerInfoFooter';

// const styleRule = ({ theme, isBusy, }) => ({
//   backgroundColor: 'rgba(200,200,200, 0.3)',
//   height: '100%',
// });

RespView282.propTypes = {
  image: PropTypes.shape({}),
  link: PropTypes.string.isRequired,
  linkTarget: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

RespView282.defaultProps = {
  image: null,
};

/**
 * A Simple ClickTrackerBanner view.
 * It's purpose is to display a certain banner according to passed-down props.
 * @export RespView282 which is a pure function component (get props, returns JSX)
 * @param {*} { clicktrackerimage, link, linkTarget, text, } destructuerd props
 * @returns a banner component to be displayed.
 */
export default function RespView282({
  image,
  link,
  linkTarget,
  text,
  note,
}) {
  // do some pre-processing
  const { css, } = useFela();
  const articleIdPattern = /^.*(1\.\d+){1}$/;
  const linkArticeId = articleIdPattern.exec(link);

  return (
    <EventTracker>
      {({ gaAction, }) => (
        <HtzLink
          href={link}
          target={linkTarget}
          onClick={() => gaAction({
            category: 'editorial article- desktop',
            label: 'Labels Clicks',
            action: linkArticeId && linkArticeId[1],
          })
          }
          content={(
            <FelaComponent
              style={({ theme, }) => ({
                position: 'relative',
                backgroundColor: '#F6F6F6',
                width: '100%',
                height: '22rem',
                display: 'flex',
                extend: [
                  border(
                    '1px',
                    0.0001,
                    'solid',
                    theme.color('neutral', '-5')
                  ),
                ],
              })}
            >
              {({ className, theme, }) => (
                <div className={className}>
                  <Image
                    lazyLoad
                    image={image}
                    hasWrapper={false}
                    imgOptions={{
                      sizes:
                        '(min-width:1420px) 610px,(min-width:1320px) 500px, 280px',
                      transforms: [
                        {
                          width: '252',
                          height: '152',
                          aspect: 'landscape',
                          quality: 'auto:best',
                        },
                      ],
                    }}
                    miscStyles={{ backgroundColor: 'transparent', }}
                  />
                  <div className={css({
                    display: 'grid',
                    gridTemplateAreas: "'content''content''content''promoted'",
                    paddingStart: '1rem',
                  })}
                  >
                    <FelaComponent
                      style={{
                        height: '100%',
                        paddingTop: '3rem',
                        paddingRight: '1rem',
                        paddingLeft: '1rem',
                        paddingBottom: '3rem',
                        fontFamily: theme.fontStacks.commercial,
                        fontWeight: 'bold',
                        textAlign: 'start',
                        color: theme.color('neutral'),
                      }}
                    >
                      {text}
                    </FelaComponent>
                    <ClickTrackerInfoFooter companyName={note || ''} />
                  </div>
                </div>
              )}
            </FelaComponent>
)}
        />
      )}
    </EventTracker>
  );
}
