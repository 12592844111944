// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import setColor from '../../utils/setColor';
import IconComment from '../Icon/icons/IconComment';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

type Props = {
  /* pass comments count from polopoly if exists */
  commentsCount?: ?number,
  /**
   * minimum number of comments, if commentsCount is less than this nothing will be shown
   */
  minCount: number,
  /* icon and text color */
  color: ?ColorPropType,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  iconMiscStyles: ?StyleProps,
  textMiscStyles: ?StyleProps,
};

CommentsCount.defaultProps = {
  commentsCount: null,
  minCount: 5,
  color: [ 'teaserFooter', 'comments', ],
  miscStyles: null,
  iconMiscStyles: null,
  textMiscStyles: null,
};

const wrapperStyle = ({ miscStyles, theme, }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginInlineEnd: '1rem',
  extend: [
    // Trump all other styles with those defined in `miscStyles`
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const commentCountTextStyle = ({ color, theme, textMiscStyles, }) => ({
  fontWeight: 'bold',
  paddingInlineEnd: '0.5rem',
  extend: [
    color
      ? parseComponentProp('color', color, theme.mq, setColor, theme.color)
      : { color: 'inherit', },
    // Trump all other styles with those defined in `textMiscStyles`
    ...(textMiscStyles ? parseStyleProps(textMiscStyles, theme.mq, theme.type) : []),
  ],
});

function CommentsCount({
  commentsCount,
  minCount,
  color,
  miscStyles,
  iconMiscStyles,
  textMiscStyles,
}: Props): React.Node {
  const { css, theme, } = useFela({ miscStyles, textMiscStyles, color, });
  const wrapperClasses = css(wrapperStyle);
  const countClasses = css(commentCountTextStyle);

  return commentsCount && commentsCount >= minCount ? (
    <span className={wrapperClasses}>
      {commentsCount ? (
        <div className={countClasses}>
          {commentsCount}
          <VisuallyHidden>{theme.commentsCountI18n.countSuffix}</VisuallyHidden>
        </div>
      ) : null}
      <IconComment
        attrs={{ 'aria-hidden': true, }}
        color={color}
        size={2.3}
        miscStyles={{
          position: 'relative',
          top: '-.12em',
          ...(iconMiscStyles || {}),
        }}
      />
    </span>
  ) : null;
}

export default CommentsCount;
