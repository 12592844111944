// @flow

import * as React from 'react';
import {
  type StyleProps,
  borderTop,
  parseComponentProp,
  parseTypographyProp,
  parseStyleProps,
} from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import Query from '../ApolloBoundary/Query';
import type { ClickTrackerBannerWrapperType, } from '../../flowTypes/ClickTrackerBannerWrapperType';
import type { ClickTrackerBannerType, } from '../../flowTypes/ClickTrackerBannerType';
import type { ListExtraLinkType, } from '../../flowTypes/ListExtraLinkType';
import type { ListMarketingTeaserType, } from '../../flowTypes/ListMarketingTeaserType';
import type { ListBiActionType, } from '../../flowTypes/ListBiActionType';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import useDarkModeChecker from '../../hooks/useDarkModeChecker';

import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import IconBack from '../Icon/icons/IconBack';
import setColor from '../../utils/setColor';
import { getUser, } from './getUser';
import MarketingTeaserView from './MarketingTeaserView';
import ExtraLinks from './ExtraLinks';
import ClickTracker from '../ClickTracker/ClickTrackerWrapper';
import Image from '../Image/Image';
import getImageAssets from '../../utils/getImageAssets';

export type ListViewHeaderPropTypes = {
  /** is the list header horizontal on large viewports */
  isHorizontal: boolean,
  /** is the list header sticky on mobile viewports */
  isSticky: boolean,
  /** Is the list's title padded at its inline start across breakpoints */
  hasTitlePadding: boolean,
  /**
   * Is the list title should include top-border
   */
  isTopBorderDisabled: boolean,
  /**
   * Should the arrow that appears on mobile for headers
   * that serve as links be disabled
   */
  isUrlArrowDisabled: boolean,
  /**
   * The background color of the <ListViewHeader />.
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   *   - An array of objects representing media queries, in
   *     the following structure:
   *     ```
   *     {
   *       from?: string,
   *       until?: string,
   *       misc?: string,
   *       value: string or tuple, as mentioned above,
   *     }
   *     ```
   */
  backgroundColor: ?ColorPropType,
  /** Overrides the default top-border and title text colors .
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   */
  customColor: ?ColorPropType,
  /**
   * overrides customColor on mobile breakpoints
   */
  customMobileColor: ?ColorPropType,

  clickTracker?: ?ClickTrackerBannerWrapperType,
  /**
   * description of the list to display
   */
  description: ?string,
  /** A list of links to display. */
  extraLinks: ?(ListExtraLinkType[]),
  extraLinksMiscStyles: ?StyleProps,
  /** A commercial link and text. */
  commercialLinks: ?(ListExtraLinkType[]),
  /** A marketing tool, title and subTitle. */
  marketingTeaser: ?ListMarketingTeaserType,
  /** The List Title. */
  title: ?string | ?Array<string>,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values of the tile. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  titleMiscStyles: ?StyleProps,
  additionalTitleMiscStyles: ?StyleProps,
  descriptionMiscStyles?: ?StyleProps,

  /** URL that leads to section of list. */
  url: ?string,
  /** Is it vertical list. */
  isVertical: boolean,
  isCommercial: boolean,
  /**
   * Useful for bi actions and events
   *
   * Should also be passed to underlying links, e.g.,
   * around the title and image
   */
  biAction: ?ListBiActionType,
  disableGridArea: boolean,
};

ListViewHeader.defaultProps = {
  isCommercial: false,
  isHorizontal: false,
  isSticky: true,
  hasTitlePadding: false,
  isTopBorderDisabled: false,
  isUrlArrowDisabled: false,
  backgroundColor: [ { until: 's', value: [ 'bg', 'base', ], }, ],
  customColor: null,
  customMobileColor: null,
  clickTracker: null,
  isVertical: false,
  commercialLinks: null,
  marketingTeaser: null,
  extraLinks: null,
  extraLinksMiscStyles: null,
  additionalTitleMiscStyles: null,
  descriptionMiscStyles: null,
  miscStyles: null,
  titleMiscStyles: null,
  title: null,
  url: null,
  biAction: null,
  disableGridArea: false,
  description: null,
};

export default function ListViewHeader({
  isCommercial,
  isHorizontal,
  isSticky,
  isVertical,
  hasTitlePadding,
  isTopBorderDisabled,
  isUrlArrowDisabled,
  backgroundColor,
  commercialLinks,
  customColor,
  customMobileColor,
  clickTracker,
  extraLinks,
  extraLinksMiscStyles,
  marketingTeaser,
  miscStyles,
  titleMiscStyles,
  additionalTitleMiscStyles,
  descriptionMiscStyles,
  title,
  url,
  biAction,
  disableGridArea,
  description,
}: ListViewHeaderPropTypes): React.Node {
  const safeCustomColor = customColor
    ? Array.isArray(customColor)
      ? customColor
      : [ customColor, ]
    : null;
  const safeMobileCustomColor = customMobileColor
    ? Array.isArray(customMobileColor)
      ? customMobileColor
      : [ customMobileColor, ]
    : null;
  const { css, theme, } = useFela({
    backgroundColor,
    customColor: safeCustomColor,
    customMobileColor: safeMobileCustomColor,
    disableGridArea,
    hasTitlePadding,
    isTopBorderDisabled,
    isCommercial,
    isHorizontal,
    isSticky,
    isVertical,
    miscStyles,
    titleMiscStyles,
    additionalTitleMiscStyles,
    descriptionMiscStyles,
  });
  const headerClasses = css(listViewHeaderStyle);

  const isMultipleTitle = Array.isArray(title);

  return (
    <header className={headerClasses}>
      {title && isMultipleTitle ? (
        <div
          className={css({
            display: 'grid',
            minWidth: '100%',
            ...(additionalTitleMiscStyles
              ? {
                ...theme.mq({ until: 's', }, { gridTemplateColumns: '1fr auto', backgroundColor: '#2d2d2d', }),
                ...theme.mq({ from: 's', until: 'l', }, { gridTemplateColumns: '1fr auto', }),
                ...theme.mq({ from: 'l', }, { gridTemplateColumns: '1fr', }),
              }
              : isCommercial
                ? { ...theme.mq({ until: 's', }, { display: 'grid', gridTemplateColumns: 'auto auto', alignItems: 'baseline', }),
                  ...theme.mq({ from: 'xl', }, { display: 'flex', justifyContent: 'space-between', }), } : { gridTemplateColumns: 'auto 1fr', }),
          })}
        >
          <Title
            url={url}
            title={title[0]}
            hasTitlePadding={hasTitlePadding}
            isCommercial={isCommercial}
            isHorizontal={isHorizontal}
            isVertical={isVertical}
            isUrlArrowDisabled={isUrlArrowDisabled}
            customColor={safeCustomColor}
            customMobileColor={safeMobileCustomColor}
            titleMiscStyles={titleMiscStyles}
            css={css}
            theme={theme}
          />
          <p
            className={css({
              justifySelf: 'end',
              alignSelf: 'center',
              extend: [
                theme.type(-3, { fromBp: 'm', }),
                theme.type(-1, { untilBp: 'm', }),
                theme.mq(
                  { from: 'xl', },
                  {
                    alignSelf: 'end',
                  }
                ),
                ...(additionalTitleMiscStyles
                  ? parseStyleProps(additionalTitleMiscStyles, theme.mq, theme.type)
                  : []),
              ],
            })}
          >
            {title[1]}
          </p>
        </div>
      ) : title ? (
        <Title
          url={url}
          title={title}
          hasTitlePadding={hasTitlePadding}
          isCommercial={isCommercial}
          isHorizontal={isHorizontal}
          isVertical={isVertical}
          isUrlArrowDisabled={isUrlArrowDisabled}
          customColor={safeCustomColor}
          customMobileColor={safeMobileCustomColor}
          titleMiscStyles={titleMiscStyles}
          css={css}
          theme={theme}
        />
      ) : null}
      {description ? (
        <p
          className={css({
            marginTop: '2rem',
            extend: [
              theme.type(-1),
              theme.mq({ until: 's', }, { display: 'none', }),
              theme.mq({ from: 's', until: 'l', }, { marginTop: '1rem', }),
              ...(descriptionMiscStyles
                ? parseStyleProps(descriptionMiscStyles, theme.mq, theme.type)
                : []),
            ],
          })}
        >
          {description}
        </p>
      ) : null}
      {extraLinks ? (
        <ExtraLinks {...{ extraLinks, isHorizontal, css, theme, biAction, extraLinksMiscStyles, }} />
      ) : null}
      {isHorizontal ? (
        clickTracker ? (
          <ClickTrackerItem {...clickTracker} />
        ) : null
      ) : commercialLinks || (marketingTeaser && marketingTeaser.href) ? (
        <div
          className={css({
            marginTop: 'auto',
            extend: [ theme.mq({ until: 'l', }, { display: 'none', }), ],
          })}
        >
          {marketingTeaser ? (
            <MarketingTeaser marketingTeaser={marketingTeaser} />
          ) : (
            <CommercialLinks
              commercialLinks={commercialLinks}
              css={css}
              theme={theme}
              biAction={biAction}
            />
          )}
        </div>
      ) : null}
    </header>
  );
}

/* eslint-disable react/prop-types */
function Title({
  title,
  url,
  hasTitlePadding,
  isCommercial,
  isHorizontal,
  isVertical,
  isUrlArrowDisabled,
  customColor,
  customMobileColor,
  titleMiscStyles,
}) {
  const { theme, css, } = useFela({
    isCommercial,
    customColor,
    customMobileColor,
    isHorizontal,
    hasTitlePadding,
    titleMiscStyles,
  });

  /* eslint-enable react/prop-types */
  const titleClasses = css(titleStyles);
  const linkClasses = css(theme.mq({ until: 's', }, { display: 'flex', width: '100%', }));
  const isDarkMode = useDarkModeChecker();

  if (!title) return null;
  return url ? (
    <HtzLink className={linkClasses} href={url}>
      <H className={titleClasses}>
        {title}
        {isUrlArrowDisabled ? null : (
          <IconBack
            size={7}
            miscStyles={{
              marginInlineStart: 'auto',
              paddingTop: '1rem',
              paddingBottom: '1rem',
              transform: theme.listViewHeader.title.iconBackTransform,
              fontFamily: isCommercial ? theme.fontStacks.commercial : null,
              ...(isCommercial ? { color: theme.color('neutral', '-10'), }
                : { color: theme.color('list', 'listViewHeaderIconBack'), backgroundColor: theme.color('list', 'listViewHeaderIconBackBg'), }),
              display: [ { from: 's', value: 'none', }, ],
            }}
          />
        )}
      </H>
    </HtzLink>
  ) : (
    <>
      <H className={titleClasses}>
        {title}
      </H>
      {isCommercial ? (
        <div className={css({
          fontFamily: theme.fontStacks.commercial,
          color: isDarkMode ? theme.color('neutral', '-6') : theme.color('neutral', '-1'),
          fontWeight: 400,
          paddingEnd: '1rem',
          justifySelf: 'end',
          whiteSpace: 'nowrap',
          extend: [
            theme.type(-1),
            theme.mq({ until: 's', }, { paddingTop: '1.5rem', height: '100%', }),
            theme.mq({ from: 's', until: 'l', }, { paddingTop: '0.5rem', }),
            theme.mq({ from: 'l', }, { paddingTop: '0.7rem', }),
          ],
        })}
        >
          {theme.labelsI18n.advertorialContent}
        </div>
      ) : null}
    </>
  );
}

// eslint-disable-next-line react/prop-types
function MarketingTeaser({ marketingTeaser, }) {
  return (
    <Query query={getUser}>
      {({ loading, error, data, }) => {
        if (loading || error || !data || data.user.type === 'paying') return null;
        return <MarketingTeaserView marketingTeaser={marketingTeaser} />;
      }}
    </Query>
  );
}

// eslint-disable-next-line react/prop-types
function CommercialLinks({ commercialLinks, biAction, }) {
  const { theme, css, } = useFela();
  const headerClasses = css({
    color: theme.color('commercial'),
    fontFamily: theme.fontStacks ? theme.fontStacks.commercial : undefined,
    extend: [ theme.type(-1), ],
  });
  const ulClasses = css({
    color: theme.color('neutral', '-3'),
    fontFamily: theme.fontStacks ? theme.fontStacks.commercial : undefined,
    extend: [
      theme.mq({ until: 'l', }, { display: 'none', }),
      theme.type(0, {
        fromBp: 'l',
        untilBp: 'xl',
        lines: 4,
      }),
      theme.type(-2, { fromBp: 'xl', lines: 4, }),
    ],
  });

  return commercialLinks ? (
    <React.Fragment>
      <H className={headerClasses}>{theme.commercialListI18n.text}</H>

      <ul className={ulClasses}>
        {/* eslint-disable-next-line react/prop-types */}
        {commercialLinks.map((commercialLink, idx) => (
          <li
            className={css({
              //  eslint-disable-next-line react/prop-types
              marginBottom: idx < commercialLinks.length - 1 ? '1rem' : '0',
              '&:hover': { textDecoration: 'underline', },
              '&:focus': { textDecoration: 'underline', },
            })}
            key={commercialLink.contentId}
          >
            <HtzLink href={commercialLink.href} onClick={biAction}>
              {commercialLink.contentName}
            </HtzLink>
          </li>
        ))}
      </ul>
    </React.Fragment>
  ) : null;
}

function listViewHeaderStyle({
  theme,
  backgroundColor,
  customColor,
  customMobileColor,
  disableGridArea,
  isCommercial,
  isHorizontal,
  isSticky,
  isTopBorderDisabled,
  isVertical,
  miscStyles,
}) {
  const borderColor = customColor
    ? theme.color(...(Array.isArray(customColor) ? customColor : [ customColor, ]))
    : isCommercial
      ? theme.color('list', 'listViewHeaderCommercial')
      : theme.color('list', 'listViewHeader');

  const borderMobileColor = customMobileColor
    ? theme.color(...(Array.isArray(customMobileColor) ? customMobileColor : [ customMobileColor, ]))
    : borderColor;

  return {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    justifyContent: isCommercial ? (isVertical ? 'space-between' : null) : 'flex-start',
    alignItems: isHorizontal ? 'baseline' : 'flex-start',
    gridArea: disableGridArea ? null : 'he',
    extend: [
      ...(isSticky ? [ theme.mq({ until: 's', }, {}), ] : []),
      (isVertical
        ? parseTypographyProp(theme.listStyle.header.verticalType, theme.type)
        : parseTypographyProp(theme.listStyle.header.type, theme.type)),
      ...(backgroundColor
        ? [
          parseComponentProp<ColorPropType>(
            'backgroundColor',
            backgroundColor,
            theme.mq,
            setColor,
            theme.color
          ),
        ]
        : []),
      theme.mq(
        { until: 's', },
        {
          ...(isTopBorderDisabled ? {} : borderTop('1px', 0, 'solid', borderMobileColor)),
          alignItems: 'center',
          position: isSticky ? 'sticky' : 'static',
          top: '0',
          zIndex: theme.getZIndex('stickyListViewHeader'),
        }
      ),
      theme.mq(
        { from: 's', until: isHorizontal ? null : 'l', },
        isTopBorderDisabled
          ? {}
          : {
            ...borderTop('2px', 1, 'solid', borderColor),
            paddingBottom: '1rem',
          }
      ),
      ...(isHorizontal
        ? []
        : [
          theme.mq(
            { from: 'l', },
            isTopBorderDisabled
              ? {}
              : borderTop('5px', 2, 'solid', borderColor)
          ),
          theme.mq({ from: 'l', }, { flexDirection: 'column', }),
        ]),
      // Trump all other styles with those defined in `miscStyles`
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function titleStyles({
  isCommercial,
  customColor,
  customMobileColor,
  isHorizontal,
  hasTitlePadding,
  titleMiscStyles,
  theme,
}) {
  return {
    ...(isCommercial
      ? {
        color: theme.color(...(customColor || [ 'list', 'listViewHeaderCommercial', ])),
        fontFamily: theme.fontStacks.commercial,
        flexGrow: isHorizontal ? 1 : null,
        extend: [
          theme.mq({ until: 'm', backgroundColor: theme.color('neutral', '-7'), }),
        ],
      }
      : {
        color: theme.color(...(customColor || [ 'list', 'listViewHeader', ])),
      }),
    fontWeight: 700,
    extend: [
      isHorizontal && hasTitlePadding
        ? { paddingInlineStart: '1rem', paddingTop: '0.2rem', }
        : theme.mq({ until: 's', }, { paddingInlineStart: '1rem', }),
      theme.mq(
        { until: 's', },
        {
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          ...(customMobileColor ? { color: theme.color(...customMobileColor), } : {}),
        }
      ),
      // Trump all other styles with those defined in `miscStyles`
      ...(titleMiscStyles ? parseStyleProps(titleMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}


function ClickTrackerItem(item: ClickTrackerBannerWrapperType) {
  const { css, theme, } = useFela();
  return (
    <ClickTracker
      key={item.contentId}
      {...item}
      render={(banner: ClickTrackerBannerType) => {
        const { image, url, } = banner;
        return (
          <HtzLink
            href={url}
            className={css({
              marginRight: 'auto',
              extend: [ theme.mq({ until: 's', }, { display: 'none', }), ],
            })}
          >
            <Image
              hasWrapper={false}
              image={image}
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'full',
                sizes: [ { from: 'xl', size: '144px', }, { size: '144px', }, ],
                widths: [ 144, 250, ],
              })}
            />
          </HtzLink>
        );
      }}
    />
  );
}
