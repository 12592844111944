import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import config from 'config';
import { stylesPropType, } from '../../propTypes/stylesPropType';


import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';

const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;

const isHDC = siteNumber === 85;

Point.propTypes = {
  addAnimation: PropTypes.bool,
  isLiveUpdateDot: PropTypes.bool,
  diameter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      })
    ),
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Point.defaultProps = {
  addAnimation: true,
  isLiveUpdateDot: true,
  diameter: [ { until: 'xl', value: 1.5, }, { from: 'xl', value: 'calc(1rem + 2px)', }, ],
  color: 'tertiary',
};


function Point({ color, addAnimation, diameter, isLiveUpdateDot, }) {
  const { css, theme, } = useFela();

  return (
    <span
      className={css({
        borderRadius: '50%',
        backgroundColor: color,
        marginInlineStart: isHDC && !isLiveUpdateDot ? undefined : '0.5rem',
        marginInlineEnd: isHDC && !isLiveUpdateDot ? undefined : '1rem',
        marginBottom: '0.2rem',
        ...(addAnimation ? { animationDuration: '1s',
          animationIterationCount: 'infinite',
          animationDirection: 'alternate',
          animationTimingFunction: 'linear',
          animationName: {
            '0%': { opacity: '0', },
            '30%': { opacity: '0', },
            '50%': { opacity: '1', },
            '100%': { opacity: '1', },
          }, } : {}),
        extend: [
          parseComponentProp('diameter', diameter, theme.mq, setSize),
        ],
      })}
      aria-hidden
    />
  );
}

function LiveUpdateView({
  text,
  diameter,
  color: colorName,
  miscStyles,
  textMiscStyles,
  showDiameter,
  Tag,
  href,
  addAnimation,
  isLiveUpdateDot,
}) {
  const { css, theme, } = useFela();
  const color = theme.color(...(Array.isArray(colorName) ? colorName : [ colorName, ]));
  return (
    <Tag
      className={css(({
        display: 'inline-flex',
        alignItems: 'center',
        extend: [
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      }))}
      href={href}
    >
      {isHDC && !isLiveUpdateDot && showDiameter ? (
        <Point diameter={diameter} addAnimation={addAnimation} color={color} />
      ) : null}
      <span
        className={css({
          color,
          margin: isHDC && !isLiveUpdateDot ? 'auto 1rem' : 'auto',
          fontWeight: 'bold',
          extend: [
            ...(textMiscStyles ? parseStyleProps(textMiscStyles, theme.mq, theme.type) : []),
          ],
        })}
      >
        {text || theme.liveBlogI18n.liveUpdate}
      </span>
      {(!isHDC || isLiveUpdateDot) && showDiameter ? (
        <Point diameter={diameter} addAnimation={addAnimation} color={color} />
      ) : null}
    </Tag>
  );
}

LiveUpdateView.propTypes = {
  Tag: PropTypes.oneOfType(PropTypes.string, PropTypes.elementType),
  href: PropTypes.string,
  text: PropTypes.string,
  showDiameter: PropTypes.bool,
  isLiveUpdateDot: PropTypes.bool,
  addAnimation: PropTypes.bool,
  diameter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      })
    ),
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  miscStyles: stylesPropType,
  textMiscStyles: stylesPropType,
};

LiveUpdateView.defaultProps = {
  Tag: 'span',
  text: null,
  href: null,
  addAnimation: true,
  showDiameter: true,
  isLiveUpdateDot: false,
  diameter: [ { until: 'xl', value: 1.5, }, { from: 'xl', value: 'calc(1rem + 2px)', }, ],
  color: 'tertiary',
  miscStyles: null,
  textMiscStyles: null,
};

function setSize(prop, value) {
  const diameter = typeof value === 'number' ? `${value}rem` : value;
  return {
    width: diameter,
    height: diameter,
  };
}

export default LiveUpdateView;
